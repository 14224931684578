<template>
  <div class="col wrapper_for_printing">
    <div class="bg-white p-3 mb-4 rounded">
      <div class="row">
        <div class="col-4">
          <multiselect
            placeholder="Select neomi test type"
            :searchable="false"
            :show-labels="true"
            :close-on-select="true"
            v-model="nemoi_exam_type"
            :multiple="false"
            :options="nemoiExams"
            label="name"
            track-by="id"
            @select="dropdownExamReport"
          ></multiselect>
        </div>
      </div>
    </div>

    <div id="while_printing_the_report" class="while_printing_the_report">
      <div class="information">
        <div class="part_one">
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.name") }} :</span>
            <span class="when_name">{{ userDataForPrintTwo.name }}</span>
          </div>
          <div class="parts text-capitalize">
            <span>{{ $t("gender") }} :</span>
            <span class="when_name">
              {{
                userDataForPrintTwo.gender == 1
                  ? $t("admin_dashboard_type.male")
                  : $t("admin_dashboard_type.female")
              }}</span
            >
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.age") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.age }}</span>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.class_name") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.group_name }}</span>
          </div>
          <div class="parts spans">
            <div
              class="is_span"
              v-if="
                $route.params.exam_type == 5 || $route.params.exam_type == 6
              "
            >
              <span v-if="$route.params.exam_type == 5"
                >{{ $t("admin_dashboard_type.pre") }} 1</span
              >
              <span v-if="$route.params.exam_type == 6"
                >{{ $t("admin_dashboard_type.pre") }} 2</span
              >
              <span
                class="border_span"
                :class="{
                  pre:
                    $route.params.exam_type == 5 ||
                    $route.params.exam_type == 6,
                }"
              ></span>
            </div>
            <div
              class="is_span ml-4"
              v-if="
                $route.params.exam_type == 7 || $route.params.exam_type == 8
              "
            >
              <span v-if="$route.params.exam_type == 7"
                >{{ $t("admin_dashboard_type.post") }} 1</span
              >
              <span v-if="$route.params.exam_type == 8"
                >{{ $t("admin_dashboard_type.post") }} 2</span
              >
              <span
                class="border_span"
                :class="{
                  post:
                    $route.params.exam_type == 7 ||
                    $route.params.exam_type == 8,
                }"
              ></span>
            </div>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.Total time") }}:</span>
            <span class="when_name"
              >{{ calculateMinAndSec(userDataForPrintTwo.total_time) }}
            </span>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.Total inputs/time") }}:</span>
            <span class="when_name">{{ userDataForPrintTwo.time_count }}</span>
          </div>
          <div class="parts">
            <span>{{ $t("admin_dashboard_type.country") }}:</span>
            <span class="when_name text-capitalize">{{
              userDataForPrintTwo.country
            }}</span>
          </div>
        </div>
      </div>
      <div class="is_old_wrapper">
        <div
          class="main_images_checks_container with_english_direction"
          :class="{ with_english_direction: getLocale == 'en' }"
        >
          <div
            class="wrapper_of_images"
            v-for="(item, index) in printPdfReportData.answers"
            :key="index"
          >
            <div class="main_main">
              <div class="wrapper_two">
                <!-- <div class="loop_here" v-for="(img, i) in item.object" :key="i"> -->
                <img
                  v-for="(img, i) in item.object"
                  :key="i"
                  :style="{ transform: `rotate(${-img.rotation}deg)` }"
                  class="item"
                  :src="img.img_path"
                  alt="draw image"
                  :class="`custom_img_en_class_${i + 1}`"
                />
                <!-- </div> -->
              </div>
              <div
                class="text_images d-flex justify-content-between align-items-center px-2 pt-2"
              >
                <div>
                  <span>({{ index + 1 }})</span>
                  <span>{{ item.answer }}</span>
                </div>
                <div
                  @click="deleteAnswer(item.id)"
                  class="delete_answer_img d-flex justify-content-center align-items-center shadow-sm cursor-pointer"
                >
                  <i
                    v-tooltip.top-center="$t('Delete_Answer')"
                    class="fa fa-trash icon_answer_img"
                  ></i>
                </div>
              </div>
            </div>
            <div class="is_items_with_checkbox">
              <div class="is_is_checker">
                <span>{{ item.negative ? "Negative" : "Negative" }}</span>
                <el-checkbox v-model="item.negative"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{
                  item.extra_details ? "Extra Details" : "Extra Details"
                }}</span>
                <el-checkbox v-model="item.extra_details"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.diagonal ? "Diagonal" : "Diagonal" }}</span>
                <el-checkbox v-model="item.diagonal"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.asymmetric ? "Asymmetric" : "Asymmetric" }}</span>
                <el-checkbox v-model="item.asymmetric"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.rotated ? "Rotated" : "Rotated" }}</span>
                <el-checkbox v-model="item.rotated"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{
                  item.uncompleted ? "Uncompleted" : "Uncompleted"
                }}</span>
                <el-checkbox v-model="item.uncompleted"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.with_bg ? "With BG" : "With BG" }}</span>
                <el-checkbox v-model="item.with_bg"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.not_clear ? "Not Clear" : "Not Clear" }}</span>
                <el-checkbox v-model="item.not_clear"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.rejected ? "Rejected" : "Rejected" }}</span>
                <el-checkbox v-model="item.rejected"></el-checkbox>
              </div>
              <div class="is_is_checker">
                <span>{{ item.nice_look ? "Nice Look" : "Nice Look" }}</span>
                <el-checkbox v-model="item.nice_look"></el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        @click="handlerSubmitAnswers"
        class="sharing_wrapper for_printing_buttons mt-4"
        v-if="answersLength > 0"
      >
        <span class="">{{ $t("send") }}</span>
      </div>
      <div class="p-3 mt-4 rounded footer_buttons_wrapper">
        <button
          class="next_previous_btn"
          @click="getPreviousStudentGroup"
          :class="{ not_active: theFirstStudent }"
          :disabled="theFirstStudent"
        >
          Previous
        </button>
        <button
          class="next_previous_btn"
          @click="getNextStudentGroup"
          :class="{ not_active: theLastStudent }"
          :disabled="theLastStudent"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      activate: [],
      testData: {},
      answersLength: 0,
      submitAnswersData: {
        gender: "",
        age: "",
        answers: [],
        exam_type: null,
        user_id: null,
      },
      correctedAll: false,
      notCorrectedAnswers: [],
      // Start For Correct Steps
      nemoi_exam_type: null,
      nemoiExams: [
        {
          id: 5,
          challenge_no: 1,
          name: "Pre 1",
        },
        {
          id: 6,
          challenge_no: 1,
          name: "Pre 2",
        },
        {
          id: 7,
          challenge_no: 1,
          name: "Post 1",
        },
        {
          id: 8,
          challenge_no: 1,
          name: "Post 2",
        },
      ],
      theLastStudent: false,
      theFirstStudent: false,
    };
  },
  mounted() {
    this.printExamReport();
    this.refreshData();
  },
  computed: {
    printPdfReportData() {
      return this.$store.getters["groups/printPdfReportDataNumberTwo"];
    },
    userDataForPrintTwo() {
      return this.$store.getters["groups/userDataForPrintTwo"];
    },
    getLocale() {
      return this.$i18n.locale;
    },
    usersGroupsData() {
      return this.$store.getters["groups/getNewNeomiTwoExamsData"];
    },
  },
  methods: {
    printExamReport() {
      let exData = {
        student_id: this.$route.params.student_id,
        exam_type: this.$route.params.exam_type,
        challenge_no: this.$route.params.challenge_no,
      };

      this.$store
        .dispatch("groups/handlerPrintExamReportForMark", exData)
        .then((res) => {
          // console.log(res);
          this.answersLength = res.answers.length;
        });
    },

    handlerSubmitAnswers() {
      // handle gender and age
      this.submitAnswersData.age = this.userDataForPrintTwo.age;
      if (this.userDataForPrintTwo.gender == 2) {
        this.submitAnswersData.gender = "female";
      } else {
        this.submitAnswersData.gender = "male";
      }
      // handle answers and image object
      let allData = this.printPdfReportData.answers;
      let answersCorrection = [];
      this.notCorrectedAnswers = [];
      allData.forEach((element) => {
        if (
          element.negative == false &&
          element.extra_details == false &&
          element.diagonal == false &&
          element.asymmetric == false &&
          element.rotated == false &&
          element.uncompleted == false &&
          element.with_bg == false &&
          element.not_clear == false &&
          element.rejected == false &&
          element.nice_look == false
        ) {
          this.notCorrectedAnswers.push(element);
        } else {
          answersCorrection.push({
            id: element.id,
            answer_title: element.answer,
            negative: element.negative,
            extra_details: element.extra_details,
            diagonal: element.diagonal,
            asymmetric: element.asymmetric,
            rotated: element.rotated,
            uncompleted: element.uncompleted,
            with_bg: element.with_bg,
            not_clear: element.not_clear,
            rejected: element.rejected,
            nice_look: element.nice_look,
          });
        }
      });

      this.submitAnswersData.answers = answersCorrection;
      this.submitAnswersData.user_id = this.$route.params.student_id;
      this.submitAnswersData.exam_type = this.$route.params.exam_type;

      if (this.notCorrectedAnswers.length >= 1) {
        Swal.fire({
          text: `${this.$t("You have to assess all the test")}`,
          icon: "error",
          showCancelButton: false,
          confirmButtonClass: "btn btn-success btn-fill",
          confirmButtonText: this.$t("confirm"),
          buttonsStyling: false,
        });
      } else {
        this.$store
          .dispatch("groups/handlerSubmitExamReportForMark", {
            // answers: this.submitAnswersData,
            answers: answersCorrection,
            user_id: this.$route.params.student_id,
            exam_type: this.$route.params.exam_type,
          })
          .then(() => {
            Swal.fire({
              text: `${this.$t("sent_successfully")}`,
              icon: "success",
              showCancelButton: false,
              confirmButtonClass: "btn btn-success btn-fill",
              confirmButtonText: this.$t("confirm"),
              buttonsStyling: false,
            });
            // this.printExamReport();
          });
        this.$store.dispatch(
          "groups/handlerSubmitExamToAi",
          this.submitAnswersData
        );
      }
    },
    calculateMinAndSec(seconds) {
      if (seconds) {
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;
        return `${minutes} : ${remainingSeconds}`;
      }
    },
    refreshData() {
      this.$store.dispatch("groups/getNewNeomiExamsData");
    },
    // Display User Info
    // Get Student -- Next
    getNextStudentGroup() {
      let studentIdFromUrl = this.$route.params.student_id;
      let neomiExamType = this.$route.params.exam_type;
      let groupContainUser = [];
      let currentGroupIndex = 0;
      let currentUserIndex = 0;
      console.log("usergroup",this.usersGroupsData);
      this.usersGroupsData.forEach((group, index) => {
        group.users.forEach((everyUserInGroup, i) => {
          if (everyUserInGroup.id == studentIdFromUrl) {
            groupContainUser = group;
            currentGroupIndex = index;
            currentUserIndex = i;
          }
        });
      });
      // const studentUser = this.usersGroupsData[currentGroupIndex].users[currentUserIndex];

      currentUserIndex++;
      if (
        currentUserIndex >= this.usersGroupsData[currentGroupIndex].users.length
      ) {
        currentUserIndex = 0; // Wrap around to the first user
        console.log("last");
        this.theLastStudent = true;
      }
      const studentUser =
        this.usersGroupsData[currentGroupIndex].users[currentUserIndex];
      // console.log(groupContainUser);
      // console.log("currentGroupIndex", currentGroupIndex);
      // console.log("currentUserIndex", currentUserIndex);
      // console.log("studentUser", studentUser);
      this.$router.replace(`/correct-neomi-exam/${studentUser.id}/1/${neomiExamType}`);
      this.printExamReport();
    },
    // Get Student -- Previous
    getPreviousStudentGroup() {
      let studentIdFromUrl = this.$route.params.student_id;
      let neomiExamType = this.$route.params.exam_type;
      let groupContainUser = [];
      let currentGroupIndex = 0;
      let currentUserIndex = 0;
      this.usersGroupsData.forEach((group, index) => {
        group.users.forEach((everyUserInGroup, i) => {
          if (everyUserInGroup.id == studentIdFromUrl) {
            groupContainUser = group;
            currentGroupIndex = index;
            currentUserIndex = i;
          }
        });
      });
      // const studentUser = this.usersGroupsData[currentGroupIndex].users[currentUserIndex];

      currentUserIndex--;
      // if (
      //   currentUserIndex >= this.usersGroupsData[currentGroupIndex].users.length
      // ) {
      //   currentUserIndex = 0; // Wrap around to the first user
      //   console.log("first");
      //   this.theFirstStudent = true;
      //   console.log(this.usersGroupsData[currentGroupIndex].users.length);
      // }
      // console.log(this.usersGroupsData[currentGroupIndex].users.length);
      const studentUser =
        this.usersGroupsData[currentGroupIndex].users[currentUserIndex];
      // console.log(groupContainUser);
      // console.log("currentGroupIndex", currentGroupIndex);
      // console.log("currentUserIndex", currentUserIndex);
      // console.log("studentUser", studentUser);

      this.$router.replace(`/correct-neomi-exam/${studentUser.id}/1/${neomiExamType}`);
      this.printExamReport();
    },

    // For Dropdown
    dropdownExamReport() {
      this.$router.replace(
        `/correct-neomi-exam/${this.$route.params.student_id}/1/${this.nemoi_exam_type.id}`
      );
      this.printExamReport();
    },
    deleteAnswer(id) {
      Swal.fire({
        text: `${this.$t("you_want_delete")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("cancel")}`,
        buttonsStyling: false,
      }).then((status) => {
        if (status.isConfirmed) {
          this.$store
            .dispatch("groups/deleteExamAnswerWhenCorrect", { answer_id: id })
            .then((_) => {
              this.printExamReport();
              Swal.fire({
                text: `${this.$t("success_deleted")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin drawImg($left, $top) {
  left: $left;
  top: $top;
  z-index: 99;
}
.sharing_wrapper {
  width: fit-content;
  min-width: 150px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
.while_printing_the_report {
  background: #fff;
  padding: 20px;
  // changes
  // direction: ltr;
  // &.reverse_dir {
  //   direction: rtl;
  // }
}
.is_old_wrapper {
  .main_images_checks_container {
    width: 800px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    background: #fff;
    margin: 30px auto;
    flex-direction: row-reverse;
    justify-content: center;
    .wrapper_of_images {
      display: flex;
      gap: 15px;
      margin-bottom: 10px;
      // start container for images
      .main_main {
        .wrapper_two {
          width: 200px;
          height: 200px;
          background: #fff;
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          .item {
            display: block;
            width: 40px;
            height: 40px;
          }
          // }
        }
        .text_images {
          display: flex;
          justify-content: center;
          gap: 6px;
        }
      }
      // end container for images
      .is_items_with_checkbox {
        .is_is_checker {
          display: flex;
          justify-content: start;
          gap: 8px;
          flex-direction: row-reverse;
        }
      }
    }

    // Start When English - Direction == Ltr
    &.with_english_direction {
      //direction: rtl; // here the problem
      .main_main {
        .wrapper_two {
          width: 200px;
          background: #ddd;
          height: 200px;
          // margin: auto;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          .item {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;

            &.custom_img_en_class_1 {
              @include drawImg(0, 0);
            }
            &.custom_img_en_class_2 {
              @include drawImg(40px, 0);
            }
            &.custom_img_en_class_3 {
              @include drawImg(80px, 0);
            }
            &.custom_img_en_class_4 {
              @include drawImg(120px, 0);
            }
            &.custom_img_en_class_5 {
              @include drawImg(160px, 0);
            }
            &.custom_img_en_class_6 {
              @include drawImg(0, 40px);
            }
            &.custom_img_en_class_7 {
              @include drawImg(40px, 40px);
            }
            &.custom_img_en_class_8 {
              @include drawImg(80px, 40px);
            }
            &.custom_img_en_class_9 {
              @include drawImg(120px, 40px);
            }
            &.custom_img_en_class_10 {
              @include drawImg(160px, 40px);
            }
            &.custom_img_en_class_11 {
              @include drawImg(0, 80px);
            }
            &.custom_img_en_class_12 {
              @include drawImg(40px, 80px);
            }
            &.custom_img_en_class_13 {
              @include drawImg(80px, 80px);
            }
            &.custom_img_en_class_14 {
              @include drawImg(120px, 80px);
            }
            &.custom_img_en_class_15 {
              @include drawImg(160px, 80px);
            }
            &.custom_img_en_class_16 {
              @include drawImg(0, 120px);
            }
            &.custom_img_en_class_17 {
              @include drawImg(40px, 120px);
            }
            &.custom_img_en_class_18 {
              @include drawImg(80px, 120px);
            }
            &.custom_img_en_class_19 {
              @include drawImg(120px, 120px);
            }
            &.custom_img_en_class_20 {
              @include drawImg(160px, 120px);
            }
            &.custom_img_en_class_21 {
              @include drawImg(0, 160px);
            }
            &.custom_img_en_class_22 {
              @include drawImg(40px, 160px);
            }
            &.custom_img_en_class_23 {
              @include drawImg(80px, 160px);
            }
            &.custom_img_en_class_24 {
              @include drawImg(120px, 160px);
            }
            &.custom_img_en_class_25 {
              @include drawImg(160px, 160px);
            }
          }
          // }
        }
      }
    }
  }
}

.is_items_with_checkbox {
  .is_is_checker {
    display: flex;
    // justify-content: start;
    justify-content: end;
    gap: 8px;
    // flex-direction: row-reverse;
    // margin-bottom: 5px;
  }
}

.information {
  display: flex;
  background: #fff;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 30px;
  .part_one {
    display: flex;
    flex-wrap: wrap;
    .parts {
      display: flex;
      width: 50%;
      margin-bottom: 15px;
      &.spans {
        // display: flex;
        gap: 20px;
        .is_span {
          display: flex;
          gap: 5px;
          .border_span {
            width: 25px;
            height: 25px;
            border: 1px solid #000;
            &.pre {
              background: #87cb16;
              border-color: #87cb16;
            }
            &.post {
              background: #87cb16;
              border-color: #87cb16;
            }
          }
        }
      }
      .when_name {
        margin: 0 5px;
        color: #555;
      }
    }
  }
  .part_two {
    .every {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: 8px;
      .is_name {
        min-width: 100px;
      }
      .item_border {
        border: 1px solid #000;
        width: 40px;
        height: 37px;
        &.date {
          width: 120px;
        }
      }
    }
  }
}

.footer_buttons_wrapper {
  display: flex;
  justify-content: space-between;
  background: #f7f7f8;
  .next_previous_btn {
    min-width: 120px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffa534;
    border: 1px solid #ffa534;
    color: #fff;
    border-radius: 8px;
    transition: 0.3s;
    &.not_active {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  .while_printing_the_report {
    height: 100%;
    width: 100%;
    page-break-after: always !important;
  }
  .for_printing_buttons {
    display: none;
  }
  .wrapper_for_printing:last-child {
    page-break-after: avoid;
    page-break-inside: avoid;
    margin-bottom: 0px;
  }
  .while_printing_the_report:last-child {
    page-break-after: avoid;
    page-break-inside: avoid;
    margin-bottom: 0px;
  }
}
::v-deep .el-checkbox {
  margin-bottom: 0;
}

.delete_answer_img {
  background: #eee;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  .icon_answer_img {
    color: #fb404b;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    background-color: #fb404b;
    .icon_answer_img {
      color: #fff;
    }
  }
}
</style>
